import { ReactElement, useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useFeatureFlagEnabled } from "posthog-js/react";
import * as Dialog from "@radix-ui/react-dialog";
import { CreatorProfilePicture } from "~/components/Agency/CreatorProfilePicture";
import { ZodInput } from "../Input/ZodInput";
import { useZodForm } from "~/utils/hooks/zod-form";
import { trpc } from "~/components/Utility/trpc";
import { CreatorProfile, UpdateCreatorProfileSchema } from "@withjuly/fabric";
import { Loader } from "@withjuly/solis";
import { Button, useToast, ScrollArea } from "@withjuly/solisv2";
import { cx } from '@withjuly/solisv2/src/classnames';
import { PencilSimple } from "@withjuly/julycons/regular";
import { Plus, Gear, Plugs, X } from "@withjuly/julycons/bold";
import { FacebookAccounts, InstagramAccounts, TikTokAccounts, TwitchAccounts, YouTubeAccounts } from "~/components/ConnectAccounts/Handles";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";

type CreatorSettingsMenuOptions = 'general' | 'profiles';

/**
 * Creator settings modal
 */
interface CreatorSettingsModalProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	creatorProfile: CreatorProfile;
}

export const CreatorSettingsModal: React.FC<CreatorSettingsModalProps> = ({
	isOpen,
	setIsOpen,
	creatorProfile,
}) => {
	const router = useRouter();
	const params = useSearchParams();
	const [activePage, setActivePage] = useState<CreatorSettingsMenuOptions>("general");

	useEffect(() => {
		const settingsParam = params.get('settings');
		if (settingsParam !== null && settingsParam !== activePage) {
			setActivePage(settingsParam as CreatorSettingsMenuOptions ?? 'general');
		}
	}, [params]);

	// Maintain settings modal state in the URL
	useEffect(() => {
		if (isOpen === true) {
			router.replace(router.pathname, { query: { settings: activePage } }, { shallow: true });
		} else if (isOpen === false && params.has('settings')) {
			router.replace(router.pathname, undefined, { shallow: true });
		}
	}, [isOpen, activePage]);

	return (
		<Dialog.Root
			open={isOpen}
			onOpenChange={(isOpen) => {
				setIsOpen(isOpen);
				if (!isOpen) {
					setActivePage("general");
				}
			}}
		>
			<Dialog.DialogPortal style={{ pointerEvents: "none" }}>
				<div className="font-repro bg-sky-1/75 pointer-events-auto fixed inset-0 z-[20] h-screen w-screen data-[state=closed]:animate-[fade-out_0.10s_ease-in-out_forwards] data-[state=open]:animate-[fade-in-no-scale_250ms_ease-in-out]" />
				<Dialog.Content
					className="bg-surface-primary border-stroke-secondary fixed inset-0 z-[30] mt-16 flex rounded-t-3xl lg:rounded-3xl border overflow-clip focus:outline-none data-[state=open]:animate-[fade-in_0.10s_ease-in-out] lg:m-8" // data-[state=closed]:animate-[fade-out_0.10s_ease-in-out_forwards]
					onInteractOutside={(e) => e.preventDefault()}
				>
					<Dialog.Close className="bg-surface-primary border-stroke-secondary absolute right-4 top-4 z-[100] rounded-full border transition-all duration-150 ease-in-out focus:outline-none">
						<button className="hover:bg-surface-hover-1 bg-surface-primary flex h-10 min-h-10 w-10 min-w-10 items-center justify-center rounded-full">
							<X />
						</button>
					</Dialog.Close>

					<div className="flex w-full">
						<CreatorSettingsMenu
							creatorProfile={creatorProfile}
							activePage={activePage}
							setActivePage={setActivePage}
						/>
						<ScrollArea className="h-full w-full">
							<div className="flex h-full w-full flex-col items-center pt-8">
								{activePage === "general" && <GeneralTab creatorProfile={creatorProfile} />}
								{activePage === "profiles" && <ProfilesTab />}
							</div>
						</ScrollArea>
					</div>
				</Dialog.Content>
			</Dialog.DialogPortal>
		</Dialog.Root>
	);
};

/**
 * Creator settings navigation side menu
 */
interface Link {
	id: CreatorSettingsMenuOptions,
	name: string,
	onClick: () => void,
	enabled: boolean | undefined,
	icon: ReactElement,
}

interface CreatorSettingsMenuProps {
	creatorProfile: CreatorProfile;
	activePage: CreatorSettingsMenuOptions;
	setActivePage: (page: CreatorSettingsMenuOptions) => void;
}

const CreatorSettingsMenu = ({
	creatorProfile,
	activePage,
	setActivePage,
}: CreatorSettingsMenuProps) => {
	const isNoAuthEnabled = useFeatureFlagEnabled("no-auth-talent");

	const menuLinks: Link[] = [
		{
			id: 'general',
			name: "Settings",
			onClick: () => setActivePage("general"),
			enabled: true,
			icon: <Gear />
		},
		{
			id: "profiles",
			name: "Social Media Profiles",
			onClick: () => setActivePage("profiles"),
			enabled: isNoAuthEnabled,
			icon: <Plugs />
		},
	];

	return (
		<div className="relative flex h-full w-fit flex-col font-repro">
			<div className="bg-surface-secondary border-r border-r-stroke-secondary py-8 h-full lg:w-[352px] lg:min-w-[352px] lg:max-w-[352px]">
				<div className="flex flex-col gap-5 lg:gap-8 px-4">
					{/* Menu Profile */}
					<div className="lg:ml-4 flex items-center gap-3">
						<CreatorProfilePicture creator={creatorProfile} size={40} />
						<p className="hidden lg:flex text-header-xl font-repro">
							{creatorProfile.user.displayName ??
								`${creatorProfile.user.firstName} ${creatorProfile.user.lastName}`}
						</p>
					</div>

					{/* Menu Links */}
					<div className="flex flex-col gap-3 lg:gap-1">
						{menuLinks
							.filter((link: Link) => link.enabled)
							.map((link: Link) => (
								<button
									key={link.id}
									onClick={link.onClick}
									className={cx(
										'text-button-sm hover:bg-surface-hover-1 transiton-all flex h-10 w-full items-center gap-3 rounded-full text-text-secondary',
										link.id === activePage && "text-text-primary bg-surface-hover-1"
									)}
								>
									{/* Full tab name on wide screen */}
									<div className="hidden lg:flex w-full pl-4 pr-[10px] text-button-sm">
										{link.name}
									</div>
									{/* Icon on narrow screen */}
									<div className="flex lg:hidden w-full items-center justify-center">
										{link.icon}
									</div>
								</button>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

/**
 * General tab
 */
interface GeneralTabProps {
	creatorProfile: CreatorProfile;
}

export const GeneralTab = ({ creatorProfile }: GeneralTabProps) => {
	const { toast } = useToast();
	const uploadRef = useRef<HTMLInputElement | null>(null);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const utils = trpc.useContext();
	const getUploadUrl = trpc.user.getProfilePictureUploadUrl.useMutation();
	const updateCreator = trpc.creator.update.useMutation({
		onSuccess: () => {
			utils.user.current.invalidate();
		},
	});

	const form = useZodForm({
		schema: UpdateCreatorProfileSchema,
		values: creatorProfile
			? {
				...creatorProfile,
				username: creatorProfile.username ?? "",
				hasCompletedOnboarding: creatorProfile.user.hasCompletedOnboarding,
			}
			: undefined,
		submit: async (data, { onFormError }) => {
			setIsSaving(true);
			updateCreator.mutate(data, {
				onError: onFormError,
				onSuccess: () => {
					toast({
						title: "Account Updated",
						description: "Your account information was updated.",
						variant: "success",
					});
				},
				onSettled: () => {
					setIsSaving(false);
				}
			});
		},
	});

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsUploading(true);
		const file = e.target.files?.[0];

		if (file) {
			if (file.size > 2_000_000) {
				toast({
					title: "That file is too large",
					description: "Please upload a file less than 2MB",
					variant: "error",
				});
				e.target.value = "";
				return;
			}

			const { url, fields, upload } = await getUploadUrl.mutateAsync({
				fileName: file.name,
				fileType: file.type,
				size: file.size,
			});

			const formData = new FormData();
			Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			await fetch(url, {
				method: "POST",
				body: formData,
			});

			form.setValue("user.profilePicture.uuid", upload.uuid);
			await updateCreator.mutateAsync(form.watch(), {
				onSuccess: () => utils.agency.get.invalidate(),
			});
		}

		setIsUploading(false);
	};

	return (
		<div className="flex h-full w-full flex-col py-8 px-4 max-w-[640px]">
			<div className="flex flex-col gap-8">
				<div className="flex w-full flex-col items-center justify-center gap-8 pb-4">
					{creatorProfile.user.profilePicture ? (
						<div
							className="relative cursor-pointer"
							onClick={() => uploadRef.current?.click()}
						>
							<img
								className="shadow-40 h-24 w-24 rounded-full object-cover object-center"
								alt={`${creatorProfile.user.firstName}'s profile picture`}
								src={creatorProfile.user.profilePicture.url}
							/>
							<div className="bg-surface-hover-1 border-stroke-tertiary absolute bottom-0 right-0 flex h-8 w-8 items-center justify-center rounded-full border backdrop-blur-md transition-all disabled:cursor-not-allowed disabled:opacity-40">
								{isUploading ? <Loader /> : <PencilSimple />}
							</div>
						</div>
					) : (
						<div
							className="bg-surface-tertiary flex h-24 w-24 cursor-pointer items-center justify-center rounded-full"
							onClick={() => uploadRef.current?.click()}
						>
							<Plus />
						</div>
					)}
					<input
						className="hidden"
						type="file"
						ref={uploadRef}
						onChange={onUpload}
					/>
				</div>
				<FormProvider {...form}>
					<form onSubmit={form.onSubmit} className="flex flex-col gap-6">
						<ZodInput
							name="user.email"
							label="Email Address"
							placeholder="Add email"
						/>

						<div className="flex items-center gap-4">
							<ZodInput
								name="user.firstName"
								label="First Name"
								placeholder="Add first name"
							/>
							<ZodInput
								name="user.lastName"
								label="Last Name"
								placeholder="Add last name"
							/>
						</div>

						<ZodInput
							name="user.displayName"
							label="Display name (Optional)"
							placeholder="Add display name"
							description="Use if you don't go by your actual name on social media."
						/>

						<ZodInput
							name="pronouns"
							label="Pronouns"
							placeholder="Add pronouns"
						/>

						<div className="flex items-end gap-4">
							<ZodInput
								className="gap-0"
								name="username"
								label="July Username"
								placeholder=""
								leadingIcon={() => <span className="text-text-secondary">july.bio/</span>}
							/>
							<Button disabled={!form.formState.isDirty || isSaving} isLoading={isSaving}>
								Save
							</Button>
						</div>
					</form>
				</FormProvider>
			</div>
		</div>
	)
};

/**
 * Profiles tab
 */
export const ProfilesTab = () => {
	const isFacebookConnectionEnabled = useFeatureFlagEnabled(
		"facebook-connection",
	);

	return (
		<div className="mx-auto flex w-full max-w-[640px] flex-col gap-6 py-10 px-4">
			<div className="border-stroke-secondary rounded-2xl border">
				{isFacebookConnectionEnabled ? <FacebookAccounts /> : null}
				<InstagramAccounts />
				<TikTokAccounts />
				<YouTubeAccounts />
				<TwitchAccounts />
			</div>
		</div>
	);
};

