import { useRouter } from "next/router";
import { nonNull } from "@withjuly/fabric";
import { Text } from "@withjuly/solis";
import { JulyLogo } from "@withjuly/julycons";
import { Gear, SignOut } from "@withjuly/julycons/bold";

interface CreatorNavigationProps {
	onOpenSettings: () => void;
}

export const CreatorNavigation = ({
	onOpenSettings,
}: CreatorNavigationProps) => {
	const router = useRouter();

	const sectionLinks = [
		{
			name: "Settings",
			onClick: onOpenSettings,
			icon: <Gear />,
		},
		{
			name: "Sign out",
			onClick: () => router.push("/signout"),
			icon: <SignOut />,
		},
	].filter(nonNull);

	return (
		<div className="bg-surface-secondary flex w-full flex-row justify-between items-center px-8 py-5">
			<JulyLogo className="h-6 w-fit" />

			<div className="flex items-center justify-between gap-8">
				{sectionLinks.map((link, index) => (
					<button
						key={index}
						className="flex items-center gap-2 rounded text-gray-200 transition-all duration-150 ease-in-out hover:text-gray-100"
						onClick={link.onClick}
					>
						{link.icon}
						<Text>{link.name}</Text>
					</button>
				))}
			</div>
		</div>
	);
};
