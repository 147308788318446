import { cx } from "@withjuly/frontend-common";
import Image from "next/image";
import { RouterOutput } from "../Utility/trpc";

export const CreatorProfilePicture = ({
	creator,
	size = 40,
}: {
	creator:
		| RouterOutput["agency"]["get"]["creators"][number]
		| RouterOutput["agency"]["getCreatorDetails"];
	size?: number;
}) => {
	const isInvitePending = creator.industries.length === 0;

	if (creator.user.profilePicture) {
		return (
			<div
				className="relative"
				style={{
					width: size,
					height: size,
					minWidth: size,
					minHeight: size,
				}}
			>
				<Image
					src={creator.user.profilePicture.url}
					alt=""
					fill
					className={cx(
						"rounded-full object-cover object-center",
						isInvitePending && "opacity-25",
					)}
				/>
			</div>
		);
	} else {
		return (
			<div
				style={{
					width: size,
					height: size,
					minWidth: size,
					minHeight: size,
				}}
				className={cx(
					"text-bold-sm flex items-center justify-center rounded-full bg-gray-500 uppercase",
					isInvitePending && "opacity-25",
				)}
			>
				{creator.user.displayName
					? creator.user.displayName[0]
					: creator.user.firstName[0] ?? ""}
			</div>
		);
	}
};
